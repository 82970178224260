import { useAuth } from '@unbooking/ui-auth';
import { useQuery } from 'react-query';

export function useDoPermissions() {
  const { axios, isAuthenticated } = useAuth();

  const { data } = useQuery({
    queryKey: ['dopermissions'],
    queryFn: () =>
      axios
        .get(process.env.REACT_APP_HBH_API + '/v2/user-profile/')
        .then(() => true)
        .catch(() => false),
    enabled: isAuthenticated,
  });

  return !!data;
}
