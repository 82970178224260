import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// what on Earth is this??

function DigitalOffice() {
  const hasAccessDO = true;

  return hasAccessDO ? <Outlet /> : <Navigate to='/' />;
}

export { default as routes } from './routes';
export default DigitalOffice;
