import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function useParamsParse() {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const country = query.get('facility_country');
  const facility = query.get('facility_id');

  useEffect(() => {
    if (country && facility) {
      localStorage.setItem('country', country);
      localStorage.setItem('facility', facility);
    }
  }, [country, facility]);

  return {
    isParsed: !!(country && facility),
  };
}
