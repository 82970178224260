import React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from '@unbooking/ui-auth';
import { authConfig } from '@common/utils';
import { BrowserRouter as Router } from 'react-router-dom';
import '../node_modules/@unbooking/ui-kit/style.css';
import { QueryClientProvider, QueryClient } from 'react-query';
import { RepositoryProvider } from '@context/repository.context';
import axios from 'axios';
import './assets/styles/index.scss';

const axiosInstance = axios.create();
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <AuthProvider authConfig={authConfig} axiosInstance={axiosInstance}>
      <QueryClientProvider client={queryClient}>
        <RepositoryProvider>
          <Router>
            <App />
          </Router>
        </RepositoryProvider>
      </QueryClientProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
