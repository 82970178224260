import type { AxiosInstance } from 'axios';
import AxiosRepository from './axios.repository';
import ICMSRepository from '../../interfaces/CMSRepository';
import { cmxAxios } from '@common/utils/axios';

class CMSRepository extends AxiosRepository implements ICMSRepository {
  constructor(authClient: AxiosInstance) {
    super((cmxAxios as any).create(), authClient);
  }

  async getNavigation() {
    const { data } = await this.get(`/pages/find/?html_path=header-menu`);
    return data;
  }

  async getPage(url: string) {
    const { data } = await this.get(`/pages/find/?html_path=${url}`);
    return data;
  }
}

export default CMSRepository;
