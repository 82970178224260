import { AxiosInstance } from 'axios';

abstract class AxiosRepository {
  public constructor(protected axiosClient: AxiosInstance, authClient?: AxiosInstance) {
    this.axiosClient.defaults.headers = {
      ...axiosClient.defaults.headers,
      ...(authClient ? authClient?.defaults.headers : {}),
    };

    // re-applies the interceptors, rather then assigning the reference
    // to the ui-auth client interceptors
    if (authClient) {
      (authClient.interceptors.request as any).handlers.forEach((arg: any) => {
        axiosClient.interceptors.request.use(arg.fulfilled, arg.rejected);
      });
      (authClient.interceptors.response as any).handlers.forEach((arg: any) => {
        axiosClient.interceptors.response.use(arg.fulfilled, arg.rejected);
      });
    }
  }

  public get = this.axiosClient.get;
  public patch = this.axiosClient.patch;
  public put = this.axiosClient.put;
  public post = this.axiosClient.post;
  public delete = this.axiosClient.delete;
}

export default AxiosRepository;
