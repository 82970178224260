import { AxiosInstance } from 'axios';
import React, { createContext, FC, useContext } from 'react';
import EngineeringRepository from '@common/repositories/engineering.repository';
import CMSRepository from '@common/repositories/cms.repository';
import { useAuth } from '@unbooking/ui-auth';

const getRepositories = (axios: AxiosInstance) => ({
  engineeringRepository: new EngineeringRepository(axios),
  cmsRepository: new CMSRepository(axios),
});

const RepositoryContext = createContext({} as ReturnType<typeof getRepositories>);

interface IRepositoryProvider {
  children: any;
}

const RepositoryProvider: FC<IRepositoryProvider> = ({ children }) => {
  const { axios } = useAuth();

  return <RepositoryContext.Provider value={getRepositories(axios)}>{children}</RepositoryContext.Provider>;
};

const useRepository = () => useContext(RepositoryContext);

export { useRepository, RepositoryProvider };
