import React, { FC, useState, type FormEvent } from 'react';
import { Spin, message, Input } from 'antd';
import { Button, Footer } from '@ui-modules';
import { useAuth } from '@unbooking/ui-auth';
import './styles.scss';
import { ReactComponent as EngineeringLogo } from '@assets/svg/login-logo.svg';
import { ReactComponent as UNHeader } from '@assets/svg/unbooking_logo.svg';

const SignInPage = () => {
  const { authorize } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(e: FormEvent) {
    e.preventDefault();
    const email = (e.target as any).children[0].value;
    if (!email) return;
    setIsLoading(true);

    try {
      await authorize(email);
    } catch (err: any) {
      message.error(err.message);
    }

    setIsLoading(false);
  }

  return (
    <section className='login-container'>
      <div className='header'>
        <UNHeader onClick={() => (window.location.href = 'https://unbooking.org')} />
      </div>
      <div className='content'>
        <div className='content-wrapper'>
          <div className='logo'>
            <EngineeringLogo />
            <hr />
          </div>
          <p className='description'>
            Welcome to WFP&apos;s Engineering Platform, a dedicated digital touchpoint to access engineering experience,
            skills, knowledge, and services.
          </p>

          <form onSubmit={onSubmit}>
            <Input name='email' type='email' data-testid='login-input' />
            {isLoading ? (
              <Spin data-testid='loading-spinner' className='login-loading' size='large' spinning={isLoading} />
            ) : (
              <Button type='submit' text='Sign in' className='login-button' data-testid='login-button' />
            )}
          </form>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default SignInPage;
