import axios from 'axios';

export const decoupledAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_VERSION}`,
  headers: { 'Content-Type': 'application/json' },
});

export const cmxAxios = axios.create({
  baseURL: process.env.REACT_APP_CMS_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
});
