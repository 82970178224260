import React, { FC, useEffect } from 'react';
import { Routes, Route, useMatch, useNavigate, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Footer, Header, NavigationPage } from '@ui-modules';
import { useAuth } from '@unbooking/ui-auth';
import { ICrumb } from '@ui-modules/types';
import { GlobalProvider } from '@context';
import SignInPage from '@components/SignInPage';
import RedirectPage from '@digital-office/pages/RedirectPage';
import DigitalOffice, { routes as digitalOfficeRoutes } from './digital-office/app';
import { ReactComponent as HeaderTitle } from './assets/svg/header.svg';
import { ReactComponent as VisitHome } from './assets/svg/visit-home.svg';
import { ReactComponent as PanelIcon } from './assets/svg/wfp-logo-header.svg';
import PageProxy from '@components/PageProxy';
import GlobalImpact from '@components/GlobalImpact';
import ForbiddenPage from '@pages/Forbidden';
import CMSUserHeader from '@components/CMSUserHeader';
import ScrollToTop from '@components/ScrollToTop';
import useParamsParse from '@digital-office/common/hooks/useParamsParse';
import LoadingPage from '@components/LoadingPage';
import GlobalImpactDetails from '@pages/GlobalImpactDetails';
import packageJson from '../package.json';
import CommunityGuidancePage from '@components/CommunityGuidancePage';
import CountryOverviewPage from '@pages/CountryOverviewPage';
import RequestServicesPage from '@pages/RequestServicesPage';
import BookingFormPage from '@pages/BookingFormPage';
import CustomerAreaPage from '@components/CustomerAreaPage';
import CustomerProjectsList from '@components/CustomerProjectsList';
import CustomerRequestsList from '@components/CustomerRequestsList';
import CustomerRequestDetails from '@components/CustomerRequestDetails';
import RequestSuccessPage from '@pages/RequestSuccessPage';
import { useDoPermissions } from '@digital-office/common/hooks/useDoPermissions';

const ElementDO: FC<{ route: ICrumb }> = ({ route }) => {
  const match = useMatch(route.path);

  return (
    <NavigationPage
      allRoutes={digitalOfficeRoutes}
      givenRoute={route}
      homeUrl='/digital-office'
      routePath={match?.pattern.path ?? ''}
      routeParams={match?.params}
    />
  );
};

function App() {
  const navigate = useNavigate();
  const { userName, logout, isAuthenticated } = useAuth();
  const isDigitalOffice = window.location.pathname.includes('digital-office');
  const [isNavigationHidden, setIsNavigationHidden] = React.useState(false);
  const { isParsed } = useParamsParse();
  const hasAccessDO = useDoPermissions();
  const hasDoParams = localStorage.getItem('country') !== null && localStorage.getItem('facility') !== null;
  const location = useLocation();

  useEffect(() => {
    location.pathname !== '/forbidden' && setIsNavigationHidden(false);
  }, [location]);

  useEffect(() => {
    if (!hasAccessDO && isDigitalOffice) {
      navigate('/forbidden');
    }
  }, [hasAccessDO]);

  useEffect(() => {
    const SIGN_IN_ORIGIN_PAGE = sessionStorage.getItem('SIGN_IN_ORIGIN_PAGE');
    const { pathname } = window.location;

    if (SIGN_IN_ORIGIN_PAGE && isAuthenticated) {
      if (SIGN_IN_ORIGIN_PAGE.includes('digital-office') && !hasDoParams) {
        navigate('redirect', {
          state: {
            isRedirected: true,
            originPathName: SIGN_IN_ORIGIN_PAGE,
          },
        });
      } else {
        navigate(SIGN_IN_ORIGIN_PAGE, { replace: true });
      }
      sessionStorage.removeItem('SIGN_IN_ORIGIN_PAGE');
    }

    if (isAuthenticated && isDigitalOffice && !hasDoParams) {
      const REDIRECT_ORIGIN_PAGE = localStorage.getItem('REDIRECT_ORIGIN_PATHNAME');
      if (isParsed) {
        navigate(REDIRECT_ORIGIN_PAGE || 'digital-office', {
          state: {
            isRedirected: true,
          },
        });
        sessionStorage.removeItem('REDIRECT_ORIGIN_PATHNAME');
      } else {
        navigate('redirect', {
          state: {
            isRedirected: true,
            originPathName: pathname,
          },
        });
      }
    } else {
      localStorage.removeItem('REDIRECT_ORIGIN_PATHNAME');
    }
  }, []);

  return (
    <>
      <ScrollToTop />
      <GlobalProvider>
        {isAuthenticated && !isNavigationHidden ? (
          isDigitalOffice ? (
            <Header
              username={userName ?? ''}
              serviceName='Engineering'
              logout={logout}
              logo={null}
              panelIcon={<PanelIcon />}
              name={
                <div className='hbh-header-bar'>
                  <HeaderTitle className='header-name' />
                  <div onClick={() => navigate('/')} className='engineering-platform-button'>
                    <VisitHome />
                  </div>
                </div>
              }
            />
          ) : (
            <CMSUserHeader
              hasDoParams={hasDoParams}
              hasAccessDO={hasAccessDO}
              logoutHandler={logout}
              userName={userName}
            />
          )
        ) : null}
        <Routes>
          {isAuthenticated ? (
            <>
              <Route path='digital-office' element={<DigitalOffice />}>
                {digitalOfficeRoutes.map((route) => (
                  <Route
                    index={route.path === '/'}
                    path={route.path !== '/' ? route.path : undefined}
                    key={route.path}
                    element={<ElementDO route={route} />}
                  />
                ))}
              </Route>
              <Route path='redirect' element={<RedirectPage hideNavHandler={setIsNavigationHidden} />} />
              <Route path='ERAM-guidance' element={<CommunityGuidancePage />} />
              <Route path='global-impact' element={<GlobalImpact />} />
              <Route path='customer-area' element={<CustomerAreaPage />} />
              <Route path='customer-projects' element={<CustomerProjectsList />} />
              <Route path='customer-requests' element={<CustomerRequestsList />} />
              <Route path='customer-requests/:id' element={<CustomerRequestDetails />} />
              <Route path='country-overview/:id' element={<CountryOverviewPage />} />
              <Route path='global-impact/:id' element={<GlobalImpactDetails />} />
              <Route path='request-services' element={<RequestServicesPage />} />
              <Route path='request-success' element={<RequestSuccessPage />} />
              <Route path='request-services/:country/:id' element={<BookingFormPage />} />
              <Route path='callback/*' element={<LoadingPage />} />
              <Route path='forbidden' element={<ForbiddenPage hideNavHandler={setIsNavigationHidden} />} />
              <Route path='*' element={<PageProxy />} />
            </>
          ) : (
            <>
              <Route path='redirect' element={<RedirectPage hideNavHandler={setIsNavigationHidden} />} />
              <Route path='*' element={<SignInPage />} />
            </>
          )}
        </Routes>
        {isAuthenticated ? (
          <Footer
            releaseVersion={packageJson.version}
            termsOfUseLink={process.env.REACT_APP_HBH_TERMS_AND_CONDITIONS_LINK || ''}
            privatePolicyLink={process.env.REACT_APP_HBH_PRIVACY_POLICY_LINK || ''}
          />
        ) : null}
      </GlobalProvider>
    </>
  );
}

export default App;
